
import styled from "styled-components";
import lock from './assets/manta/lock.png'
import LeftImage from './assets/okx/Left.svg';
import RightImage from './assets/okx/Right.svg';
// import descImg from './assets/okx/desc.svg';
import okxPicture from './assets/okx/okxMobile.png';
// import { useWindowSize } from "react-use";

const Container=styled.div`
width:100% ;
position:relative ;
height:100vh;
padding-top:80px ;
background: #000;
overflow:hiddren ;

 `
 

const Desc = styled.img`
width:776px ;
height:auto ;
position:absolute ;
top:45%;
left:50%;
transform:translate(-50%,-50%);


@media screen and (max-width:1500px){
  width:662px ;
}

@media screen and (max-width:640px){
  width:562px ;
}

@media screen and (max-width:550px){
  width:95% ;
}
`

// const PurpleText=styled.div`
// color:#60DFFF;
// font-family: 'Galyon-Book';
// text-align:left ;
// white-space: pre-wrap;
// `

const Data=styled.div`
position:absolute ;
bottom:62px ;
margin-top:203px;
text-align:left ;
z-index:9999 ;

@media screen and (max-width:750px){
    bottom:39px ;
}
@media screen and (max-width:450px){
    bottom:100px ;
}
/* padding-top:25% ; */
 `

const Footer=styled.div`
/* display:inline-flex ; */
width:100% ;
margin-top: 14px;
font-size: 17px;
font-family: 'RedHatMono-Regular';
font-weight: normal;
line-height: 60px;
display:flex ;
align-items:center ;
padding:23px 5vw 0 5vw;
/* white-space:pre-wrap ; */
white-space:nowrap;
word-spacing:5px;

&>img{
  width:30px ;
  height:auto ;
}

&>mark::before{
  content: "&";
  font-family: 'RedHatMono-Regular';
  margin:0 10px ;
}

@media screen and (max-width:750px){
    font-size:14px ;
    line-height:13px ;

    img.lock{
      width:25px ;
      height:25px !important ;
    }
}

 `

 const Left = styled.img`
 width:296px ;
 height:586px;
 position:absolute ;
 left:0px;
 top:182px;
 z-index:100;


 @media screen and (max-width:1500px){
  top:165px;
  width:235px ;
 height:500px;
 }


 @media screen and (max-width:640px){
  width:220px ;
  height:auto ;
}

@media screen and (max-width:550px){
  display:none ;
}
 `

 const Right = styled.img`
 width:293px ;
 height:697px;
 position:absolute ;
 right:0;
 top:119px;

 @media screen and (max-width:1500px){
  top:136px;

  width:250px ;
 height:594px;
 }

 @media screen and (max-width:640px){
  width:210px ;
  height:auto ;
}
@media screen and (max-width:550px){
  display:none ;
}
 `

// const MantaPicture = styled.img`
// position:absolute;
// right:0 ;
// top:0;

// /* @media screen and (max-height:870px){
//   width:750px;
// }
// @media screen and (max-height:770px){
//   width:650px;
// }
// @media screen and (max-height:670px){
//   width:580px;
// } */
// @media screen and (max-width:1100px){
//   /* width:100% ;
//   height:auto ; */
// width:350px ;
//  height:auto;
//  left:50% ;
//  transform:translateX(-50%);
// }
// `


// Naming Service 
// on Mantle For Wallets, 
// Websites, and more
export function Linea(props){
  const {onEnterNextPage}=props;
  // const {width}=useWindowSize()

  /**
   * Naming Service on 
Manta For Wallets, 
Websites, and more
   */
    return (
      <Container>
          <Left  src={LeftImage} />
          <Right src={RightImage}/>
           <Desc src={okxPicture}/>
            <Data>
              {/* <LogoCard /> */}
              <Footer onClick={(e)=>onEnterNextPage(e)}>
                {/* Omni-chain Solution Powered  */}
                Powered  by  
                <img className="lock" src={lock} alt="" />
                LayerZero
                {/* <mark style={{color:"#60DFFF",background:"transparent",whiteSpace:"pre-wrap"}}></mark>
                <img style={{height:'16px',width:'auto',marginBottom:'5px'}} src={linea} alt="" /> */}
              </Footer>
          </Data>
      </Container>
    )
}




